<template>
  <div class="content tw-flex tw-flex-col">
    <van-search v-model="search.keyword" @keypress.enter="onRefresh" placeholder="请输入搜索关键词" show-action>
      <template #action>
        <div class="height-34 s_flex_center s_bf054 width-55 tw-text-white tw-rounded-sm" @click="onRefresh">搜索</div>
      </template>
    </van-search>
    <div id="main" class="tw-flex-1 ">
      <!-- <empty class="m-t-10" /> -->
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model:loading="loading" :finished="finished" :finished-text="list.length?'没有更多了':''" @load="onLoad" class="p-10">
          <router-link :to="'/returnGoods/orderDetail?refund_sn='+v.refund_warehousing_sn" id="card" class=" fz-14 tw-bg-white tw-rounded-sm tw-block m-b-20" tag="div" v-for="(v,k) in list" :key="k">
            <div class="fz-14 p-t-15 p-l-10">{{v.refund_warehousing_sn}}</div>
            <van-divider />
            <div class="p-l-10 p-b-10">
              <p class="fz-14 s_lin_h26">商品数量：{{v.product.length}}</p>
              <p class="fz-14 s_lin_h26">创建时间：{{v.create_time}}</p>
              <p class="fz-14 s_lin_h26">退货人：{{v.create_role_name}}</p>
            </div>
            <div class="tw-flex tw-justify-end p-r-10 p-b-10">
              <van-button class="width-80" color='#5B9DFF' block plain type="primary" size="small" >查看详情</van-button>
            </div>
          </router-link>
        </van-list>
      </van-pull-refresh>
      <van-empty v-if='!list.length' :image="require('@/assets/image/empty.png')" description="暂无退货单" />
    </div>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
import { getRefundWarehouseList } from "@/api/refundWarehouse";
import { onMounted } from "@vue/runtime-core";
import {useList} from '@/hooks/useListHandle'
export default {
  setup() {
    const { onRefresh, search,finished, refreshing, onLoad, list, loading } = useList(getRefundWarehouseList,{keyword: "",limit: 10,page: 1});
    return {
      onRefresh,
      finished,
      loading,
      refreshing,
      list,
      search,
      onLoad,
    };
  },
};
</script>
